<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    v-bind:style="{ zIndex: options.zIndex }"
  >
    <baseModal>
      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>
      <!-- <v-btn
        slot="negative-button"
        dark flat class="white--text subheading" @click.native="cancel">취소</v-btn> -->
      <v-toolbar-title
        slot="title-area"
        class="white--text">회원가입</v-toolbar-title>
      <v-card-text
        slot="content"
      >
        <v-container class="pb-0 mb-0">
          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" xs="12">
              <div class="subheading mt-1 mb-2">
                <span class="font-weight-regular mr-3">아이디</span>
                 <span class="font-weight-bold ml-2">{{ email }}</span>
              </div>
            </v-col>
            <v-col cols="12" xs="12">
              <div class="text-body-2 mt-3 mb-2 green--text text--darken-1">
                <v-icon small color="green darken-1">info</v-icon>
                필요한 경우, 개인상세정보는 [개인정보]에서 등록 하십시오.
              </div>
            </v-col>
            <v-col cols="12" xs="12">
              <v-divider class="mb-3"></v-divider>
            </v-col>
            <v-col cols="12" xs="12">
              <span class="subheading mt-3">
                이용약관
              </span>
              <v-textarea
                filled
                rows="4"
                row-height="30"
                :value="agreeMsg1"
                class="text-body-2"
              ></v-textarea>
              <span class="subheading mt-3">
                정보취급방침
              </span>
              <v-textarea
                filled
                rows="4"
                row-height="30"
                :value="agreeMsg2"
                class="text-body-2"
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12">
              <v-checkbox
                v-model="isAgree"
                label="상기 내용에 동의함"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </baseModal>
  </v-dialog>
</template>

<script>
import agreeMsgs from '../../../public/txts/registagree' // 이용약관과 정보취급방침 텍스트
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

export default {
  components: {
    baseModal
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    options: {
      color: 'primary',
      width: 600,
      zIndex: 300
    },
    isAgree: false,
    email: ''
  }),

  mounted () {
    // this.dialog = true // 테스트로 일단 팝업 띄울때

    // this.$route.query는 항상 참이다. token 여부를 체크할 때 에러가 나지 않도록 && 로 연결해야 한다
    if (this.$route.query && this.$route.query.token) {
      // 1) 정상적인 회원가입 프로세스일때 - token 이 쿼리에 담아져 올 때
      this.loginWithToken(this.$route.query.token)
      this.dialog = true
    } else if (this.$store.state.ui) {
      // 2) 이미 로그인된 상태라 저장소에 유저정보가 저장된 경우
      this.doRegistProc()
      this.dialog = true
    } else {
      // 이도저도 아니면 뭔가 잘못되었으니 초기화면으로
      this.redirect('/')
    }
  },

  computed: {
    // 이용약관 -- 다른 텍스트 파일을 읽어서 가져온다!
    agreeMsg1 () {
      return agreeMsgs.agreeMsg1
    },

    // 정보취급방침 -- 다른 텍스트 파일을 읽어서 가져온다!
    agreeMsg2 () {
      return agreeMsgs.agreeMsg2
    }
  },

  methods: {
    dummy () {
    },

    sbpop (msg) {
      this.$store.commit('SB_POP', { msg })
    },

    async agree () {
      try {
        if (!this.isAgree) {
          this.sbpop('동의함에 체크하시기 바랍니다.')
        } else {
          // 유저 이메일과 동의 여부를 보낸다. 당연히 유저 정보가 토큰에 저장된 상태다!
          const payload = {
            email: this.$store.state.ui.email,
            isAgree: 'Y'
          }
          const { data } = await this.$axios.post('auth/setIsAgree', payload)
          if (!data.success) throw new Error('[500]동의 확인에 실패하였습니다.')

          if (data.isPreRegist) {
            // 사용안함: 관리자 사전등록 유저면 첫 로그인으로
            // this.redirect('/member/firstLogin')

            // 중요: [2021.1.27] 관리자 사전등록 유저면 이용가이드로 변경
            this.redirect('/guide')
            //
          } else { // @: 아니면 회원가입 2번째 프로세스로 보낸다
            this.redirect('/auth/regist2')
            //
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },

    // 토큰을 백엔드에서 넘겨받아 로그인 처리하는 함수
    async loginWithToken (token) {
      try {
        if (token) {
          // 로그인 : 토큰 저장 + 토큰을 푼 유저정보를 저장
          await this.$store.dispatch('LOGIN', { token })

          // 유저정보 패칭
          const ui = this.$store.state.ui
          // console.log('ui :', ui)
          this.email = ui.email

          if (ui.isAgree === 'Y') {
            // 이미 동의한 경우 스킵 - 리스트 페이지로 이동(차후 수정)
            // this.redirect('/case')

            // [폐지][2021.1.28] 관리자 사전동의 회원이 아닌 회원의 경우 첫로그인이 이쪽으로 빠진다.
            this.redirect('/guide') // 여기서 이용가이드 페이지로 리디렉션한다.
          }
        } else {
          throw new Error('Token Not Reponsed : [401]')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },

    // 이미 로그인되어서 저장소에 유저정보가 있을 때!
    doRegistProc () {
      try {
        // 유저정보 패칭
        const ui = this.$store.state.ui
        // console.log('ui :', ui)
        this.email = ui.email

        if (ui.isAgree === 'Y') {
          // 이미 동의한 경우 스킵 - list 페이지로 이동(차후 수정)
          this.redirect('/case')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },

    redirect (to = '') {
      this.dialog = false
      this.$router.push(to)
    }
  }
}
</script>
